@media only screen and (max-width: 800px) {
  .paypal-container > div {
    width: 100% !important;
    /* left: 50%; */
    /* transform: translateX(-50%); */
  }
  .custom-style {
    width: 100% !important;
  }
}

@media only screen and (min-width: 800px) {
  .paypal-container > div {
    width: 100% !important;
    /* left: 50%; */
    /* transform: translateX(-50%); */
  }
  .custom-style {
    width: 100% !important;
  }
}

@media only screen and (max-width: 800px) {
  .paypal-container-2 > div {
    width: 100% !important;
    /* left: 50%; */
    /* transform: translateX(-50%); */
  }
}

@media only screen and (min-width: 800px) {
  .paypal-container-2 > div {
    width: 100% !important;
    /* left: 50%; */
    /* transform: translateX(-50%); */
  }
}
/* #buttons-container {
  max-width: 512px;
} */
