/* iPhone horizontal scroll fix */
*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  /* this makes sure the padding and the border is included in the box sizing */
  box-sizing: border-box;
  /* overflow-y: hidden; */
}

html body {
  padding: 0;
  margin: 0;
  overflow-y: inherit;
}

body {
  font-family: "Montserrat", sans-serif;
  background-color: #1e40af;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Select::placeholder {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.PhoneInput {
  /* height: 2.5rem; */
  padding: 0.5rem 0.5rem;
  margin-top: 3px;
  font-size: 1rem;
  line-height: 1.75rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.PhoneInputInput {
  background-color: #f8fafc;
}

.PhoneInput::placeholder {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.btnDefault {
  padding: 0 28px;
  border-style: solid;
  border-width: 2px;
  border-color: transparent;
  /* border-radius: 8px; */
  background-color: transparent;
  background: linear-gradient(
      95deg,
      rgba(0, 0, 0, 0.3) 15%,
      #6549d5 45%,
      #e33fa1 75%,
      #fb5343 100%
    )
    95% / 200% 100%;
  transition-duration: 300ms;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  text-shadow: rgba(0, 0, 0, 0.3);
  display: inline-block;
  height: 50px;
  line-height: 47px;
  letter-spacing: 0.5px;
  width: auto;
}

.react-dropdown-select-type-single span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 75%;
}
.react-select-button-custom {
  background: none;
  border: 1px solid #555;
  color: #555;
  /* border-radius: 3px; */
  margin: 10px 10px 0;
  padding: 3px 5px;
  font-size: 10px;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;

  &.clear {
    color: tomato;
    border: 1px solid tomato;
  }

  :hover {
    border: 1px solid deepskyblue;
    color: deepskyblue;
  }
}

.react-select-buttons-custom {
  display: flex;
  justify-content: space-between;

  & div {
    margin: 10px 0 0 10px;
    font-weight: 600;
  }
}

.react-select-item-label-custom {
  padding: 5px 10px;
}

.react-select-item-custom {
  display: flex;
  align-items: baseline;
}

.react-select-items-custom {
  overflow: auto;
  min-height: 10px;
  max-height: 200px;
}

.react-dropdown-search-custom {
  display: flex;
  flex-direction: column;
}
.react-dropdown-select-input {
  overflow-x: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 30px;
  padding: 0px 20px;
  border: 1px solid #ccc;
  /* border-radius: 3px; */
}

.react-dropdown-select-content input {
  max-width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
}
.react-dropdown-select-content {
  max-width: 94% !important;
}

.react-datepicker {
  background-color: #fff;
  border: 2px solid rgb(226, 232, 240) !important;
  font-family: "Montserrat", sans-serif !important;
}

.react-datepicker__header {
  background-color: #f8fafc !important;
  border-bottom: 2px solid rgb(226, 232, 240) !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #1d4ed8 !important;
  color: #fff !important;
}
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before {
  border-top-color: rgb(226, 232, 240) !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  font-weight: 600;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: #f8fafc;
}

.react-dropdown-select-dropdown {
  border-radius: 8px !important;
  border: 2px solid rgb(226, 232, 240) !important;
}

.react-dropdown-select-content input {
  margin-left: 0 !important;
}

.react-dropdown-select-dropdown-handle {
  color: #9ca3af;
}

.paypal-buttons {
  width: 100% !important;
  /* height: 45px !important; */
  /* background-color: #0070ba; */
  border-radius: 10px;
}

textarea:focus,
input:focus {
  outline: none;
}

.tabs-with-icons {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.tabs-with-icons div:first-child {
  border-top-left-radius: 0.375rem;
}

.tabs-with-icons div:last-child {
  border-top-right-radius: 0.375rem;
}

input[type="checkbox"] {
  min-width: 24px;
}

#truly-badge-bottom-left {
  display: none;
}

#truly-certified-badge img {
  width: 100% !important;
}
.feature {
  display: flex;
  gap: 8px;
  padding: 6px 0;
  vertical-align: middle;
}

.feature svg {
  margin: 6px;
}

.account-features.mobile .feature {
  padding: 8px 0 2px;
}

.account-features.mobile .feature svg {
  padding: 2px;
}
.react-tagsinput {
  margin-top: 0.25rem /* 4px */;
  margin-bottom: 0.25rem /* 4px */;
  background-color: #f8fafc;
  border: 1px solid #e2e8f0;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
  border-radius: 0.5rem /* 8px */;
}

.react-tagsinput--focused {
  border-color: #e2e8f0;
}

.react-tagsinput-tag {
  background-color: #cbd5e1;
  border-radius: 2px;
  border: 1px solid #94a3b8;
  color: #475569;
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: " ×";
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 80px;
}
